<template>
<div>
    <Navbar />
    <MobileMenuCabinet />
    <div class="cart">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="cart__header mb-3 pb-1 pb-md-0 mb-md-5">
                        Корзина
                    </h1>
                    <div class="cart-order" v-for="(item, index) in $store.state.cart.cart.cart_items" :key="index">
                        <div class="cart-order__date" v-if="$store.state.cart.cart.cart_items.length!=1">Заказ на {{$moment(item.date_day).format('ddd, DD.MM')}}, {{item.time}}</div>
                        <div class="cart-order__items">
                            <template v-for="(itemm,index) in item.products">
                                <CartItem :item='itemm' :cartId='item.cart_item_id' :key='index' />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-info" ref='element'>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="cart-header-sec">Дата и время получения заказа</h2>
                        <template v-if="$store.state.cart.cart.cart_items">
                            <div class="order-info__header-desc" v-if="$store.state.cart.cart.cart_items.length!=1">Выберите время выдачи заказа для каждого дня отдельно</div>
                        </template>
                        <form class="row g-3 order-info-days pt-4 mb-3" :key="componentKey" v-if="$store.state.cart.cart.cart_items.length!=1">
                            <div class="col-md-4" v-for="(item,index) in $store.state.cart.cart.times" :key="index">
                                <div class="order-info-days__wrp" @click="selectDateTime(item.date_time, item.cart_item_id)">
                                    <label for="validationServer01" class="form-label"> {{$moment(item.date2).format("dddd")}}</label>
                                    <input type="text" class="form-control is-valid" :class="item.cart_item_id==currentId?'activeInput':''" id="validationServer01" :value="item.time2" v-if="checkedDates[index]" required disabled>
                                    <input type="text" class="form-control is-invalid" :class="item.cart_item_id==currentId?'activeInput':''" id="validationServer01" :value="item.time2" v-if="!checkedDates[index]" required disabled>
                                </div>
                            </div>
                        </form>
                        <div class="select-time pt-3" v-if="order_time!=null">
                            Дата и время: <b>{{order_time}}</b>
                        </div>
                        <h4 class="order-info__sec mt-4">
                            Доступное время
                        </h4>
                        <div class="available-time pt-2">
                            <div class="available-time__item mb-2 pb-1" :key="componentKey">
                                <div v-for="(item, index,) in availableTime" :key="index">
                                    <input type="radio" :id="item.time_up" name="available-time" :value="item.time_up" @click="onChange($event)">
                                    <label :for="item.time_up" :class="{ 'active': item.is_active || item.time_up == timeup }">{{item.time_up}}</label>
                                </div>
                            </div>

                        </div>
                        <div class="available-time-detail pt-1">
                            <div v-for="(item, index,) in dropdownTime" :key="index">
                                <input type="radio" :id="item.time_down" name="available-time-detail" :value="item.time" @click="onChangeTime(item.time,item.finish_time)" :checked="item.is_active">
                                <label :for="item.time_down">{{item.time_down}}</label>
                            </div>
                        </div>

                        <div class="row order-readiness" v-if='finish_time'>
                            <div class="col-md-3">
                                <div class="order-readiness__status">
                                    Готовность заказа: <b>{{finish_time}}</b>
                                </div>
                            </div>
                            <!-- <div class="col-md-9">
                                <div class="order-readiness__desc"><b>Внимание:</b> за 30 минут до заказа вы получите SMS и можно будет изменить время на любое доступное не позднее чем за 30 минут до времени выдачи</div>
                            </div> -->
                        </div>
                        <h2 class="cart-header-sec mt-3 mt-md-5 pt-3 pb-2 pb-md-4" v-if="currentId">
                            Способ получения заказа
                        </h2>
                        <div class="way" v-if="currentId">

                            <label>
                                <input type="radio" name="test" :checked='type_way==1' @change="onChangeWay(1)" />
                                <span>На подносе</span>
                            </label>
                            <label>
                                <input type="radio" name="test" :checked='type_way==2' @change="onChangeWay(2)" />
                                <span>В контейнере </span>
                            </label>
                            <label>
                                <input type="radio" name="test" :checked='type_way==3' @change="onChangeWay(3)" />
                                <span>Доставка Яндекс.Курьер</span>
                            </label>

                        </div>
                        <template v-if="!this.$store.state.auth.token_status">
                            <CartLogin />
                        </template>

                    </div>
                </div>
            </div>

        </div>
        <div class="container" v-if="$store.state.auth.token_status">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="cart-header-sec mt-4 pt-2 pt-md-0 mt-md-5 pb-2">Оплата с внутреннего счета</h2>
                    <div class="your-sum">У вас рублей: <span>{{balance}}</span></div>
                    <div class="pay-int-account">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="pay-int-account__desc">Сколько рублей потратить на оплату заказа?</div>
                            </div>
                            <div class="col-md-7">
                                <div class="pay-int-account__btns ps-md-3 mt-3 mt-md-0">
                                    <the-mask mask="EEEEEEE" :tokens="hexTokens" class="form-control" placeholder="Сумма" v-model.trim="decreaseSumVal" />
                                    <!-- <input type="number" class="form-control" placeholder="Сумма" v-model="decreaseSumVal"> -->
                                    <button class="btn-oval btn-oval--dark" type="button" @click="decreaseSum()" :disabled='(balance==0? true : false)|| isDisable'>Применить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="total-sum">
                        <span class="d-block d-md-inline">Сумма заказа: </span> <strike class="pe-3 pe-md-0" v-if="rawPrice!=rawPriceFinal">{{rawPrice}} руб.</strike> <span class="orange">{{rawPriceFinal}} руб.</span>
                        <div class="total-kkal">
                            <div class="total-kkal__desc">Всего Ккал:&nbsp;</div>
                            <div class="total-kkal__sum">{{$store.state.cart.cart.allCalories}}</div>
                        </div>
                        <p><b>Внимание:</b> вы можете изменить заказ до 20:00 за день до его получения</p>
                        <button class="btn-oval" @click="createOrder">Оформить заказ</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="!$store.state.auth.token_status">
            <div class="row">
                <div class="col-md-12">
                    <div class="total-sum">
                        <span class="d-block d-md-inline">Сумма заказа: </span><span class="orange">{{$store.state.cart.cart.full_price}} руб.</span>
                        <p><b>Внимание:</b> вы можете изменить заказ до 20:00 за день до его получения</p>
                        <div class="total-kkal">
                            <div class="total-kkal__desc">Всего Ккал:&nbsp;</div>
                            <div class="total-kkal__sum">{{$store.state.cart.cart.allCalories}}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="container" v-if="!$store.state.auth.token_status">
            <div class="raw">
                <div class="col">
                    <div class="alert alert-warning" style="max-width:670px; margin-left:0" role="alert">
                        Для оплаты заказа необходимо зарегистрироваться!
                    </div>
                </div>
            </div>
        </div>

    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CartItem from '@/components/CartItem.vue'
import MobileMenuCabinet from '@/components/MobileMenuCabinet.vue'
import CartLogin from '@/components/CartLogin.vue'
import {
    mapState
} from 'vuex'
import {
    TheMask
} from "vue-the-mask";
export default {
    name: "Cart",
    components: {
        Navbar,
        Footer,
        CartItem,
        MobileMenuCabinet,
        CartLogin,
        TheMask,
    },
    data() {
        return {
            timeup: null,
            hexTokens: {
                F: {
                    pattern: /[7-8]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },

            phone: "",
            name: "",
            email: "",
            dob: "",
            availableTime: [],
            dropdownTime: [],
            currentId: null,
            type_way: 1,
            checkedDates: [],
            componentKey: 0,
            finish_time: null,
            order_time: null,
            balance: 0,
            rawPrice: 0,
            rawPriceFinal: 0,
            decreaseSumVal: 0
            // allowedDate: '2022-05-27'
        };
    },
    head: {
        title: {
            inner: 'Корзина',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    computed: {
        ...mapState([
            'cart'
        ]),
        isDisable() {
            if (this.balance < this.decreaseSumVal) {
                return true;
            } else {
                return false;
            }
        }
        // isDisable(){

        //     const a = this.checkedDates.length
        //      alert(a)
        // return  (this.checkedDates.indexOf(false)!=-1||this.checkedDates.length!=this.$store.state.cart.cart.times.length)?true:false
        // }
    },
    async mounted() {
        await this.$store.dispatch('actionGetCart');
        if (this.$store.state.cart.cart.cart_items.length == 0) {
            this.$router.push("/");
        }
        if (this.$store.state.auth.token_status) {
            await this.$store.dispatch("actionGetBalance");
            const balance = this.$store.state.cabinet.balance.data.balance
            this.balance = balance
        }

        // console.log('times', this.$store.state.cart.cart.times)
        // this.$store.state.cart.cart.times.map(function (elem) {
        //     console.log(elem.date); // последовательно выведет 1, 2, 3, 4, 5
        // });
        ///////////

        const rawPrice = this.$store.state.cart.cart.full_price
        this.rawPrice = rawPrice
        this.rawPriceFinal = rawPrice
        ///////////
        //Если один заказ в корзине то тянет сразу из стейта значение дат
        if (this.$store.state.cart.cart.cart_items.length == 1) {
            const currentId = this.$store.state.cart.cart.cart_items[0].cart_item_id
            this.currentId = currentId
            const availableTime = this.$store.state.cart.cart.times[0].available_time;
            this.availableTime = availableTime
            const order_time = this.$store.state.cart.cart.cart_items[0].date_time
            this.order_time = order_time
            console.log('даты', this.availableTime)
            //Preselect
            const preselectFirst = this.availableTime.find(item => item.is_active);
            if (preselectFirst) {
                console.log('preSelect', preselectFirst)
                this.dropdownTime = preselectFirst.drop_down
                const finishTime = this.dropdownTime.find(item => item.is_active);
                this.finish_time = finishTime.finish_time
            }
        }
        this.changeIconSelectTime()

    },
    watch: {
        '$store.state.cart.cart.full_price': {
            async handler() {
                if (this.$store.state.auth.token_status) {
                    this.decreaseSumVal = 0
                    const balance = this.$store.state.cabinet.balance?.data?.balance
                    this.balance = balance
                    const rawPrice = this.$store.state.cart.cart.full_price
                    this.rawPrice = rawPrice
                    this.rawPriceFinal = rawPrice
                }
                //Если один заказ в корзине то тянет сразу из стейта значение дат
                if (this.$store.state.cart.cart.cart_items?.length == 1) {
                    const currentId = this.$store.state.cart.cart.cart_items[0].cart_item_id
                    this.currentId = currentId
                    const availableTime = this.$store.state.cart.cart.times[0].available_time;
                    this.availableTime = availableTime
                    const order_time = this.$store.state.cart.cart.cart_items[0].date_time
                    this.order_time = order_time
                    console.log('даты', this.availableTime)
                    //Preselect
                    const preselectFirst = this.availableTime.find(item => item.is_active);
                    if (preselectFirst) {
                        console.log('preSelect', preselectFirst)
                        this.dropdownTime = preselectFirst.drop_down
                        const finishTime = this.dropdownTime.find(item => item.is_active);
                        this.finish_time = finishTime.finish_time
                    }
                }
            }

        },
        '$store.state.cabinet.balance.data.balance': {
            async handler() {
                const balance = this.$store.state.cabinet.balance.data.balance
                this.balance = balance
                const rawPrice = this.$store.state.cart.cart.full_price
                this.rawPrice = rawPrice
                this.rawPriceFinal = rawPrice
            }
        }
    },
    methods: {
        forceRerender() {
            this.componentKey += 1
        },
        async selectDateTime(dateTime, id) {
            this.currentId = id
            const availableTime = this.$store.state.cart.cart.times.find(item => item.date_time == dateTime);
            this.type_way = availableTime.type_of_way
            this.availableTime = availableTime.available_time
            this.dropdownTime = []
            this.finish_time = null
            this.timeup = null
            this.order_time = availableTime.date_time
            ///PreSelect
            const preselectFirst = this.availableTime.find(item => item.is_active);
            if (preselectFirst) {
                console.log('preSelect', preselectFirst)
                this.dropdownTime = preselectFirst.drop_down
                const finishTime = this.dropdownTime.find(item => item.is_active);
                this.finish_time = finishTime.finish_time
            }
            await this.$store.dispatch('actionGetCart');
            this.$forceUpdate()
        },
        async onChange(event) {
            this.timeup = event.target.value
            //await this.$store.dispatch('actionGetCart');
            const dropdownTime = this.availableTime.find(item => item.time_up == event.target.value);
            this.dropdownTime = dropdownTime.drop_down
            //     await this.$store.dispatch('actionGetCart');
            // this.forceRerender() 

        },
        async onChangeTime(time, finish_time) {
            this.finish_time = finish_time
            const data = {
                cart_item_id: this.currentId,
                time: time,
                type_of_way: this.type_way
            }
            if (this.$store.state.cart.cart.cart_items.length == 1) {
                data.cart_item_id = this.$store.state.cart.cart.cart_items[0].cart_item_id
            }
            await this.$store.dispatch("actionSelectTime", data);
            await this.$store.dispatch('actionGetCart');
            this.changeIconSelectTime()
        },
        async onChangeWay(id) {
            this.type_way = id
            const data = {
                cart_item_id: this.currentId,
                type_of_way: this.type_way
            }
            if (this.$store.state.cart.cart.cart_items.length == 1) {
                data.cart_item_id = this.$store.state.cart.cart.cart_items[0].cart_item_id
            }
            await this.$store.dispatch("actionSelectTime", data);
            await this.$store.dispatch('actionGetCart');
        },
        changeIconSelectTime() {
            this.$store.state.cart.cart.times.forEach((element, index) => {
                //console.log('targe',index)
                element.available_time.forEach(elementB => {
                    elementB.drop_down.forEach(elementC => {
                        if (this.checkedDates[index] != true) {
                            if (elementC.is_active) {
                                this.checkedDates[index] = true

                            } else {

                                this.checkedDates[index] = false
                            }
                        }
                    });
                });
            });
            this.forceRerender()
            console.log('target', this.checkedDates)
        },
        async createOrder() {
            if (this.checkedDates.indexOf(false) != -1 || this.checkedDates.length != this.$store.state.cart.cart.times.length) {
                this.$refs.element.scrollIntoView();
                this.$toast("Выберите дату и время получения заказа");
                return
            }

            const res = await this.$store.dispatch("actionCreateOrder", {
                points: this.decreaseSumVal
            });
            if(res.data.status){
              window.location.href = res.data.payment.formUrl;
            } else {
                 this.$toast(res.data.message);
                 await this.$store.dispatch('actionGetCart');
                 await this.$store.dispatch("actionGetBalance");
                 if (this.$store.state.cart.cart.cart_items.length == 0) {
                 window.location.href = '/'
                }
            }
        
            
            //  window.open(res.data.payment.formUrl)

        },
        decreaseSum() {
            if ((+this.balance - +this.decreaseSumVal >= 0) & (+this.rawPriceFinal - +this.decreaseSumVal >= 0)) {
                this.rawPriceFinal = +this.rawPriceFinal - +this.decreaseSumVal
                this.balance = +this.balance - +this.decreaseSumVal
            }

        }
    },
}
</script>

<style lang="scss" scoped>
.available-time {
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
        cursor: pointer !important;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 14px 0;
        font-family: Montserrat;
        font-size: 16px;
        border: 2px solid $orange;
        cursor: pointer !important;
        color: $orange;
        border-radius: 9px;
        margin-right: 10px;
        text-align: center;
        min-width: 160px;
    }

    label:hover {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }

    input[type="radio"]:focus+label {
        // background-color: $orange;
        // border-color: $orange;
        // color: #fff;
    }

    input[type="radio"]:checked+label {
        // background-color: $orange;
        // border-color: $orange;
        // color: #fff;
    }

    &__item {
        display: flex;
        overflow-x: scroll;
        flex-wrap: wrap;

        div {
            margin-bottom: 5px;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.available-time-detail {
    display: flex;
    overflow-x: scroll;
    flex-wrap: wrap;

    div {
        margin-bottom: 5px;
    }

    @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-size: 16px;
        border: 2px solid #3F2A2C;
        color: #3F2A2C;
        cursor: pointer !important;
        border-radius: 10px;
        margin-right: 10px;
        text-align: center;
        min-width: 144px;
    }

    label:hover {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    input[type="radio"]:focus+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }
}

.way {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
    }

    :checked+span {
        //cursor: pointer;
        border: 1px solid $orange;
        //   padding-left: 42px;
    }

    /* Optional other stuff just to make it look better */
    span {
        width: auto;
        display: flex;
        align-items: center;
        padding: 5px;
        padding-right: 51px;
        height: 56px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        border-radius: 9px;
        font-family: Montserrat;
        padding-right: 48px;
        padding-left: 50px;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
    }

    label {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 12px;
        margin-bottom: 10px;
    }

    label:hover {}

    input {

        position: absolute;
        left: 12px;
        top: 19px;
    }

    input[type='radio']:after {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ddd;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        border: 6px solid #ddd;

    }

    input[type='radio']:checked:after {
        border: 6px solid $orange;
        width: 21px;
        height: 21px;
        //  height: 9px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        //  border: 2px solid white;
    }
}

.cart {
    padding-top: 46px;

    @include media-breakpoint-down(md) {
        padding-top: 14px;
    }

    &__header {
        font-size: 45px;
        line-height: 100.74%;

        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }

    &__order {}

    &__order-date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 142.69%;

    }

    &__order-items {}

    &__img {}

    &__name {}

    &__counter {}

    &__price {}
}

.order-info {
    padding-top: 70px;
    padding-bottom: 51px;
    background: #F9F9F9;

    @include media-breakpoint-down(md) {
        padding-top: 32px;
        text-align: left;
    }

    &__header-desc {}

    &__sec {}

    &__header-desc {
        font-family: Montserrat;
        //   padding-bottom: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150.4%;

        color: #999999;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 150.4%;
            padding-bottom: 10px;
        }
    }
}

.cart-header-sec {
    font-size: 30px;
    line-height: 100.74%;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
}

.g-3 {}

.form-label {}

.form-control {}

.is-valid {}

.is-invalid {}

.select-time {
    &__change-time {}
}

.available-time {
    &__item {}
}

.available-time-detail {}

.order-readiness {
    margin-top: 37px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;

    }

    &__status {
        background: #EFEFEF;
        display: flex;
        align-items: center;
        padding-left: 30px;
        width: 318px;
        height: 74px;
        font-size: 20px;
        border-radius: 9px;

        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-right: auto;
        }

        b {
            color: $orange;
            padding-left: 10px;
        }
    }

    &__desc {
        font-family: Montserrat;
        padding-left: 20px;
        padding-top: 10px;
        font-size: 14px;
        line-height: 150.4%;
        max-width: 650px;

        @include media-breakpoint-down(md) {
            text-align: center;
            margin-top: 5px;
        }
    }
}

.way {
    @include media-breakpoint-down(md) {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.cart-phone-number {
    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;

        color: #000000;
        padding-left: 29px;

        font-weight: 600;
        font-size: 16px;

        &::placeholder {
            font-weight: 400;
        }
    }

    p {
        margin-top: 10px;
        font-family: Montserrat;

        font-size: 12px;
        line-height: 142.69%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        span {
            color: $orange;
        }
    }
}

.input-group {}

.pay-int-account {

    background: #F5F5F5;
    border-radius: 9px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
    align-items: center;
    max-width: 645px;

    @include media-breakpoint-down(md) {
        padding-top: 19px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__desc {
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 16px;
        }
    }

    &__btns {
        display: flex;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        input {
            height: 59px;
            width: 127px;
            background: #FFFFFF;
            border-radius: 73px;
            text-align: center;
            font-size: 16px;
            font-family: Montserrat;
            font-weight: bold;
            margin-right: 15px;
            border-color: #fff;

        }
    }
}

.total-sum {
    margin-top: 75px;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 35px;
        margin-bottom: 60px;
    }

    span {
        font-weight: bold;
        font-size: 30px;
        line-height: 100.74%;

        @include media-breakpoint-down(md) {
            font-size: 24px;
            margin-bottom: 17px;
        }
    }

    span.orange {
        color: $orange;
    }

    strike {
        font-size: 30px;
        line-height: 100.74%;
        color: #AFAFAF;
    }

    p {
        font-family: Montserrat;
        margin-top: 15px;
        margin-bottom: 34px;
        font-size: 14px;
        line-height: 150.4%;
        /* identical to box height, or 21px */

        color: #2B2B2B;

        @include media-breakpoint-down(md) {
            margin-top: 25px;
        }

        b {
            font-weight: 600;
        }
    }

    button {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.cart-order {
    font-family: Montserrat;
    margin-bottom: 53px;
    font-weight: 600;
    font-size: 20px;
    line-height: 142.69%;
    margin-bottom: 32px;

    &__date {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }

    &__items {}
}

.order-info-days {
    .form-control {
        font-family: Montserrat;
        font-weight: 600;
        padding-left: 0;
        font-size: 16px;
        line-height: 20px;
        padding-top: 0;
        padding-bottom: 10px;
    }

    .form-control {
        max-width: 390px;
    }

    .form-label {
        display: block;
        margin-bottom: -10px;
        font-size: 20px;

        @include media-breakpoint-down(md) {
            margin-bottom: -14px;
        }
    }

    .form-control.is-valid,
    .form-control.is-valid:focus {
        border: none;
        border-bottom: 1px solid #000000;
        box-shadow: none;
        border-radius: 0;

        background: url(../assets/true.svg) 100% 10% no-repeat #F9F9F9;
        padding-top: 20px;
    }

    .form-control.is-invalid,
    .form-control.is-invalid:focus {
        border: none;
        border-bottom: 1px solid #000000;
        box-shadow: none;
        border-radius: 0;

        background: url(../assets/false.svg) 100% 10% no-repeat #F9F9F9;
        padding-top: 20px;
    }
}

.your-sum {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 100.74%;
    margin-top: 22px;
    margin-bottom: 22px;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 10px;
    }

    span {
        color: $orange;
        font-weight: bold;
    }
}

.order-info-days__wrp {
    cursor: pointer;

    input,
    label {
        cursor: pointer;
    }
}

.order-info__sec {}

.active {
    background: #fd7e14 !important;
    color: #fff !important;
    cursor: pointer !important;
}

.activeInput {
    border-bottom: 1px solid #fd7e14 !important;
}

.select-time {
    font-size: 24px;
    line-height: 109.5%;
    position: relative;

    b {
        @include media-breakpoint-down(md) {
            display: block;
            margin-top: 7px;
        }
    }

    span {
        display: block;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.5%;
        width: fit-content;
        color: $orange;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            top: 35px;
            right: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 109.5%;
    }
}

.total-kkal {
    display: flex;
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100.74%;
    /* or 24px */
    margin-top: 15px;

    color: #000000;

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
    }
}

.total-kkal__desc {

    /* or 24px */

    color: #000000;

    @include media-breakpoint-down(md) {
        margin-bottom: 15px;
    }
}

.total-kkal__sum {
    color: $orange;
}
</style>
